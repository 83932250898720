<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
          @tabClick="tabClick"
        >
          <template v-slot:default="tab">
            <component
              :is="tab.component"
              :data.sync="data"
              :imprTabInfo.sync="imprTabInfo"
              :research.sync="research"
              :dateItems="dateItems"
              :equipmentName="popupParam.equipmentName"
              :equipmentCd="popupParam.equipmentCd"
              :grid.sync="grid"
              @getDetail="getDetail"
              @setToday="setToday"
              @imprChange="imprChange"
            />
          </template>
        </c-tab>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'equipment-daily-checking',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        dailyCheckId: '',
        equipmentCd: '',
        lineCd: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      year: '',
      grid: {
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        dailyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        lineId: '',  // 라인일련번호
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        dailyName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        utilityFlag: 'N',
        dailyResultModelList: [], // sheet
        mobileImproveModelList: [], 
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.min.equipment.plan.daily.insert.url,
      insertUrl: '',
      updateUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      currentMonth: '',
      lunarHolidays: [],
      dateItems: [],
      today: null,
      research: {
        impr: '',
        item: '',
      },
      checkboxItems: [
        // { code: null, codeName: ''},
        { code: 'CHK001', codeName: 'o'},
        { code: 'CHK002', codeName: 'X'},
        { code: 'CHK003', codeName: '●'},
        { code: 'CHK004', codeName: 'V'},
        { code: 'CHK005', codeName: '□'},
        { code: 'CHK006', codeName: '△'},
        { code: 'CHK007', codeName: '▲'},
        { code: 'CHK008', codeName: '⊙'},
      ],
      tab: 'equipmentDailyResult',
      tabItems: [
        { name: 'equipmentDailyResult', icon: 'list', label:  '점검결과', component: () => import(`${'./equipmentDailyResult.vue'}`) },
        { name: 'impr', icon: 'account_tree', label: '개선관리', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['checkItemPartName', 'checkItemName'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
        ],
        // Mobile용 End
        key: this.popupParam.dailyCheckId, // 
        ibmTaskTypeCd: 'ITT0000070',
        ibmTaskUnderTypeCd: 'ITTU000120',
        requestContents: this.data.dailyName,
        disabled: this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId),
        isOld: Boolean(this.popupParam.dailyCheckId),
        gridItem: {
          listUrl: mobileConfig.sop.min.equipment.plan.daily.get.improve.url,
          param: {
            dailyCheckId: this.popupParam.dailyCheckId,
            equipmentCd: this.popupParam.equipmentCd,
            imprFlag: true,
          },
          title: '점검결과별 개선사항 목록', // 일일순찰결과별 별 개선사항 목록
          merge: [
          ],
          columns: [
            {
              name: 'checkItemPartName',
              field: 'checkItemPartName',
              label: '부품명',
              align: 'center',
              style: 'width:100px',
              sortable: false,
              fix: true,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              align: 'center',
              style: 'width:120px',
              sortable: false,
              fix: true,
            },
            {
              name: 'dayStr',
              field: 'dayStr',
              label: '일',
              align: 'center',
              style: 'width:75px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    },
    disabled() {
      return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 170);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      // url setting
      this.getUrl = mobileConfig.sop.min.equipment.plan.daily.get.mobile.url;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.daily.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.daily.newItem.url;
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.dailyCheckId) {
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          dailyCheckId: this.popupParam.dailyCheckId,
          equipmentCd: this.popupParam.equipmentCd,
        }
        this.$http.request((_result) => {
          this.data = _result.data;
          
          this.grid.data = _result.data.dailyResultModelList
          if (this.data.checkDate.substring(5) === this.currentMonth) {
            this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
          } else {
            this.today = '01'
          }
          if (String(this.today.charAt(0)) === '0') {
            this.today = this.today.substring(1)
          }
          if (this.grid.data) {
            this.$_.forEach(this.grid.data, _item => {
              _item.colkey = 'day' + this.today; 
              if(_item['day' + this.today] && 
                  (this.$_.findIndex(this.checkboxItems, {code: _item['day' + this.today]}) === -1)) {
                _item.remark = _item['day' + this.today]
              }
            })
          }
          this.setColumns();
        },);
      }
    },
    setToday(data) {
      this.today = data;
      this.getDetail();
    },
    setColumns() {
      let _columns = [
        {
          name: 'checkItemPartName',
          field: 'checkItemPartName',
          label: '부품명',
          style: 'width:90px',
          align: 'center',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          fix: true,
        },
      ]

      let days = [];
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      let date = new Date(Number(year), Number(month), 0);

      for (let i=1; i<=date.getDate(); i++) {
        this.dateItems.push({
          dayName: i + this.$language('일'),
          dayCode: String(i),
        })
      }

      if (this.data.checkDate) { 
        days.push(
          {
            name: 'day' + this.today,
            field: 'day' + this.today,
            label: '선택',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'center',
            size: 'xs',
            setHeader: true,
            style: 'width:60px',
            comboItems: this.checkboxItems,
            type: 'custom',
            headType: 'select',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '수치입력',
            currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
                      ? true : false, 
            align: 'left',
            setHeader: true,
            style: 'width:80px',
            type: 'custom',
            headType: 'text',
            sortable: false,
          },
        )
        this.grid.columns = this.$_.concat(_columns, 
          {
            name: 'today',
            field: 'today',
            label: this.today + this.$language('일'),
            child: days
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          }
        )
        // this.grid.columns.push(
        //   {
        //     name: 'recentDay',
        //     field: 'recentDay',
        //     label: '이달최근점검',
        //     align: 'center',
        //     style: 'width:90px',
        //     sortable: false,
        //   },
        //   {
        //     name: 'checkItemCycleName',
        //     field: 'checkItemCycleName',
        //     label: '주기',
        //     align: 'center',
        //     style: 'width:50px',
        //     sortable: false,
        //   },
        // )
      }
      if (this.data.checkDate && this.data.lineId) {
        this.data.dailyName = year + '년도  ' + month + '월  ' + this.popupParam.lineCd
      }
      this.$_.forEach(this.grid.data, _item => {
        for (let i=1; i<this.today; i++) {
          if (_item['day'+i] && _item['day'+i] !== null) {
            _item.recentDay = i  + '일 [' + _item['day'+i+'Name']+ ']'
          }
        }
      })
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.getDetail();
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    }
  }
};
</script>
